import { Routes, Route } from 'react-router-dom';

import Navigation from './routes/navigation/navigation.component';
import Offer from './routes/offer/offer.component';
import CreateRequest from './routes/create-request/create-request.component';
import SignIn from './routes/sign-in/sign-in.component';
import SignUp from './routes/sign-up/sign-up-component';
import Invite from './routes/invite/invite.component';

//import './App.css';

const App = () => {

  return (
    <Routes>
      {/*<Route path='/' element={<Navigation/>}/>
      <Route path='/' element={<Invite />}>
        <Route path='offer' element={<Offer/>} /> 
        <Route path='request' element={<CreateRequest/>} />
        <Route path='sign-in' element={<SignIn />} />
        <Route path='sign-up' element={<SignUp />} />
      </Route>*/}
      <Route path='invite/ab563g28jl92j' element={<Invite />} />
    </Routes>
  );
}
{/* index por path='' */}
export default App;