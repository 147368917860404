import { useState, useContext, useEffect, createRef } from 'react';
import { LineChart, Line, Legend, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { scrollIntoView } from "seamless-scroll-polyfill";

import { RequestsContext } from '../contexts/requests.context';

import './invite-card.styles.css';
import downloadDocument from '../../assets/images/svg/doc_download.png';
import Logow from '../../assets/images/brand/logow.png';
import acceptIcon from '../../assets/images/icons/accept-icon.png';
import rejectIcon from '../../assets/images/icons/reject-icon.png';
import uploadIcon from '../../assets/images/icons/upload-icon.png';

const InviteCard = () => {

    const inputRef = createRef();

    const handleUploadClick = () => {
        // 👇 We redirect the click event onto the hidden input element
        inputRef.current?.click();
      };

    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    
    const deadline = "Febreaury, 21, 2023";
    
    const getTime = () => {
        const time = Date.parse(deadline) - Date.now();
    
        setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
    };
    
    useEffect(() => {
        const interval = setInterval(() => getTime(deadline), 1000);
    
        return () => clearInterval(interval);
    }, []);
    
      

    const { requests } = useContext(RequestsContext);

    const {id, connected_input, service_start, location, closing_date} = requests[0];
  
    const days_left = Math.floor(30*Math.random()+1);
  
    const [isExpanded, setIsExpanded] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);
  
    const expandOnly = () => {
      setIsExpanded(true);
    }

    const delay = ms => new Promise(res => setTimeout(res, ms));
  
    const shrinkAndExpand = async (e) => {
        e.stopPropagation();
        setIsExpanded(current => !current);
        await delay(450);
        const element = document.getElementById("endOfPage");
        scrollIntoView(element, { behavior: "smooth", block: "center", inline: "center" });
    }
  
    const tabHandler = (tab) => {
        setCurrentTab(tab);
    }
  
    const power_by_block_data = [
      {month: 'Ene', Día: 400, Punta: 800, Noche: 70},
      {month: 'Feb', Día: 400, Punta: 800, Noche: 70}, 
      {month: 'Mar', Día: 400, Punta: 800, Noche: 70}, 
      {month: 'Abr', Día: 600, Punta: 400, Noche: 450}, 
      {month: 'May', Día: 800, Punta: 1200, Noche: 80}, 
      {month: 'Jun', Día: 100, Punta: 150, Noche: 20},
      {month: 'Jul', Día: 400, Punta: 800, Noche: 70},
      {month: 'Ago', Día: 400, Punta: 800, Noche: 70}, 
      {month: 'Sep', Día: 400, Punta: 800, Noche: 70}, 
      {month: 'Oct', Día: 600, Punta: 400, Noche: 450}, 
      {month: 'Nov', Día: 800, Punta: 1200, Noche: 80}, 
      {month: 'Dic', Día: 100, Punta: 150, Noche: 20}
    ];
  
    const consumption_data = [
      {month: 'Ene', Consumo: 700},
      {month: 'Feb', Consumo: 1100}, 
      {month: 'Mar', Consumo: 700}, 
      {month: 'Abr', Consumo: 800}, 
      {month: 'May', Consumo: 1400}, 
      {month: 'Jun', Consumo: 300},
      {month: 'Jul', Consumo: 650},
      {month: 'Ago', Consumo: 800}, 
      {month: 'Sep', Consumo: 700}, 
      {month: 'Oct', Consumo: 600}, 
      {month: 'Nov', Consumo: 1300}, 
      {month: 'Dic', Consumo: 100}
    ];
  
    const powerByBlockChart = (
        <ResponsiveContainer aspect={2.3}>
            <LineChart data={power_by_block_data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                <Line type="monotone" dataKey="Día" stroke="#78C267" dot={false} />
                <Line type="monotone" dataKey="Punta" stroke="#d57070" dot={false} />
                <Line type="monotone" dataKey="Noche" stroke="#6D81A3" dot={false} />
                <XAxis dataKey="month" tick={{fontSize: '14px', fill: '#6D81A3'}} />
                <YAxis tick={{fontSize: '14px', fill: '#6D81A3'}} />
                <Tooltip wrapperStyle={{fontSize: '14px', opacity: 0.9}} cursor={false} />
                <Legend />
            </LineChart>
      </ResponsiveContainer>
    );
  
    const consumptionChart = (
        <ResponsiveContainer aspect={3}>
            <LineChart data={consumption_data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                <Line type="monotone" dataKey="Consumo" stroke="#6D81A3" dot={false} />
                <XAxis dataKey="month" tick={{fontSize: '14px', fill: '#6D81A3'}} />
                <YAxis tick={{fontSize: '14px', fill: '#6D81A3'}} />
                <Tooltip wrapperStyle={{fontSize: '14px', opacity: 0.9}} cursor={false} />
            </LineChart>
      </ResponsiveContainer>
    );
  
    const consumerData = [
      {atrribute: 'employees', value: 20},
      {atrribute: 'industry', value: 'Fabricación de calzado'},
      {atrribute: 'foundation', value: '20 Ene 2015'},
      {atrribute: 'legal_type', value: 'SpA'},
      {atrribute: 'company_type', value: 'Consumidor'},
      {atrribute: 'credit_rank', value: 'A'},
    ];
  
    return (
        <>
            <div className='invite-header'>
                <div className="invite-title"><img src={Logow} /></div>
            </div>
            
            <div className="invite-mega-container">
            <div className="invite-subtitle">Estimado <b>Colbún S.A.</b>, ha sido invitado a participar del proceso Conergie Nº 121.</div>
                <div className="invite-desc-container">
                    <div className="tab-selector">
                        <span className="invite-tab-title">Descripción:</span>
                        <span className="invite-description">Empresa dedicada a la fabricación de paquetería industrial busca un plan de suministro, idealmente de 2 a 4 años. Retira en Temuco, IX Región.</span>
                    </div>
                </div>
                <div className="invite-container">
                    <div className="invite-details-container">
                        <div className="invite-container-top">
                            <div className="invite-attribute">
                                <div className="invite-attribute-label">Potencia conectada</div>
                                <div className="invite-attribute-value">{connected_input} MWh</div>
                            </div>
                            <div className="invite-attribute request-location">
                                <div className="invite-attribute-label">Barra de entrega</div>
                                <div className="invite-attribute-value">BA S/E Vicuña</div>
                            </div>
                            <div className="invite-attribute request-servicestart">
                                <div className="invite-attribute-label">Fin de suministro actual</div>
                                <div className="invite-attribute-value">{service_start}</div>
                            </div>
                            <div className="invite-attribute days-left">
                                <div className="invite-attribute-label">Cierre de llamado</div>
                                <div className="invite-attribute-value">{days} días</div>
                            </div>
                        </div> 
                        <div className="invite-graph-container">
                            <div className="tab-selector">
                                <span className="invite-tab-title">Perfil de consumo:</span>
                            </div>
                            <div className="invite-container-tab-0">
                                <div className="invite-power-graphs">
                                    Potencia (MWh)
                                    {powerByBlockChart}
                                    <br/>
                                    Consumo (GWh)
                                    {consumptionChart}
                                </div>
                            </div>
                        </div>
                    </div>     
                </div>
                {/*<div className="invite-process-id">
                    Tiempo restante para ofertar: {days} días y {hours}:{minutes}:{seconds}.
                </div>*/}
                <div className="invite-timeline">
                    <span style={{fontSize: "18px"}}>Fechas del proceso:</span>
                    <ol className="c-stepper">
                        <li className="c-stepper__item">
                            <span className="c-stepper__date">6 Feb</span>
                            <span className="date-dot date-dot-current"></span>
                            <p className="c-stepper__event">Invitación a participar</p>
                            <span className="date-line"></span>
                        </li>
                        <li className="c-stepper__item">
                            <span className="c-stepper__date">21 Feb</span>
                            <span className="date-dot"></span>
                            <p className="c-stepper__event">Selección de ganador</p>
                            <span className="date-line"></span>
                        </li>
                        <li className="c-stepper__item">
                            <span className="c-stepper__date">1 Mar</span>
                            <span className="date-dot"></span>
                            <p className="c-stepper__event">Notificación a consumidor</p>
                            <span className="date-line"></span>
                        </li>
                        <li className="c-stepper__item">
                            <span className="c-stepper__date">10 Mar</span>
                            <span className="date-dot"></span>
                            <p className="c-stepper__event">Firma de contrato</p>
                            <span className="date-line-end"></span>
                        </li>
                    </ol>
                </div>
                <div className={`${isExpanded ? 'invite-container-expanded' : ''} invite-container`}>
                    <div className="invite-container-top-2">
                        ¿Desea participar del proceso?
                        <div className="invite-decision-zone">
                            <span className={`${isExpanded ? 'invite-chosen-button' : ''} invite-accept-button`} onClick={shrinkAndExpand}><img src={acceptIcon} alt="accept-icon" width={28} /> Sí</span>
                            <span className="invite-reject-button"><img src={rejectIcon} alt="reject-icon" width={22} /> No</span>
                        </div>
                    </div>
                    <span className="invite-fine-print">*Al decidir participar, usted acepta los Términos y Condiciones de uso de la plataforma.</span>
                    {isExpanded &&
                    <div className="invite-container-details">
                        {/*<div className="tab-selector">
                            <span className="tab-title tab-title-active">Información Empresa</span>
                </div>*/}
                        <div className="invite-data-title">Información del consumidor:</div>
                        <div className="invite-container-tab-1">
                            <div className="attribute-labels">
                                <div className="attribute-label">Empresa:</div>
                                <div className="attribute-label">Razón social:</div>
                                <div className="attribute-label">Medidor asignado:</div>
                                <div className="attribute-label">Archivos:</div>
                            </div>
                            <div className="attribute-value">
                                <div className="attribute-value">Faret S.A.</div>
                                <div className="attribute-value">77.581.228-44</div>
                                <div className="attribute-value">21-238</div>
                                <div className="attribute-value"><span className="request-data-download"><img src={downloadDocument} style={{height: "70px", width: "auto"}} alt="download-data" className="download-data-icon"/>
                                    Historial de consumo (CSV)</span>
                                </div>
                                <div className="attribute-value"><span className="request-data-download"><img src={downloadDocument} style={{height: "70px", width: "auto"}} alt="download-data" className="download-data-icon"/>
                                    Últimas facturas (PDF)</span>
                                </div>
                            </div>
                        </div>
                        <div className="invite-container-tab-1 invite-container-tab-1-time">
                            <div className="attribute-labels">Tiempo restante: <br></br><span className="invite-timeleft">{days} días y <span className="invite-timeleft-clock">{hours}:{minutes}:{seconds}</span></span></div>
                            <div className="attribute-value attribute-value-time" onClick={handleUploadClick}><input id="fileInput" ref={inputRef} type="file" hidden/><img src={uploadIcon} alt="upload-icon" height={70}/><br></br>Subir oferta</div>
                        </div>
                    </div>}
                <div id="endOfPage"></div>
                </div>
            
            </div>
            <div className="invite-header invite-footer">Conergie SpA - Todos los derechos reservados</div>
        </>
    )
  }

export default InviteCard;
